<template>
  <div class="skeleton-wrapper">
    <div class="card py-1 mb-2">
      <div class="card-body">
        <h4 class="mt-2 mb-2">
          <SkeletonElement type="title" />
        </h4>
        <SkeletonElement type="tag" />
        <SkeletonElement type="tag" />
        <SkeletonElement type="tag" />
        <SkeletonElement type="text text-first" />
        <SkeletonElement type="text" />
        <SkeletonElement type="text text-short" />
        <SkeletonElement type="more" />
      </div>
    </div>
    <Shimmer />
  </div>
</template>

<script>
import Shimmer from './Shimmer.vue'
import SkeletonElement from './SkeletonElement.vue'

export default {
  components: { Shimmer, SkeletonElement }
}
</script>

<style scoped>
.skeleton-wrapper {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  border-radius: 0;
  background-color: transparent;
}
</style>
