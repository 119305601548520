<template>
  <PostSkeleton v-if="loading" />
  <div v-else-if="error" class="lead text-center">{{ error }}</div>
  <div v-else-if="posts.length">
    <h3 class="fw-light mb-4 ms-1">
      Posts including “<span class="fw-medium">{{ tag }}</span
      >” tag
    </h3>
    <PostList :posts="posts" @remove="handleRemove" />
  </div>
  <div v-else class="lead text-center">No posts found!</div>
  <PostModal
    title="Remove Post"
    message="Are you sure you wish to remove this post?"
    :action="handleConfimRemove"
  />
</template>

<script>
import { useRoute } from 'vue-router'
import { ref, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'

import PostList from '../components/PostList'
import PostModal from '../components/PostModal'
import PostSkeleton from '../components/PostSkeleton'

export default {
  components: { PostList, PostModal, PostSkeleton },
  setup() {
    const removedPostId = ref(null)
    const loading = ref(false)
    const error = ref(null)
    const tag = ref('')

    const store = useStore()
    const route = useRoute()

    watchEffect(async () => {
      loading.value = true
      tag.value = route.params.tag

      try {
        await store.dispatch('posts/getPosts', [
          'tags',
          'array-contains',
          tag.value
        ])
        loading.value = false
      } catch (err) {
        error.value = err.message
        loading.value = false
      }
    })

    const posts = computed(() => store.state.posts.posts)

    const handleRemove = (id) => {
      removedPostId.value = id
    }

    const handleConfimRemove = () => {
      store.dispatch('posts/removePost', removedPostId.value)

      document.getElementsByClassName('btn-close')[0].click()
    }

    return {
      error,
      loading,
      posts,
      handleConfimRemove,
      handleRemove,
      tag
    }
  }
}
</script>