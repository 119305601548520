// firebase
import { auth } from '../../firebase/config'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile
} from 'firebase/auth'

// alerts
import { errorMsg } from '../../alerts'

export default {
  namespaced: true,
  state: {
    user: null,
    authIsReady: false
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setAuthIsReady(state, payload) {
      state.authIsReady = payload
    }
  },
  actions: {
    async register(context, { name, email, password }) {
      const res = await createUserWithEmailAndPassword(auth, email, password)

      if (res) {
        if (name) {
          await updateProfile(res.user, {
            displayName: name
          })
        }

        context.commit('setUser', res.user)
      } else {
        throw new Error(errorMsg.registered)
      }
    },
    async login(context, { email, password }) {
      const res = await signInWithEmailAndPassword(auth, email, password)
      if (res) {
        context.commit('setUser', res.user)
      } else {
        throw new Error(errorMsg.loggedIn)
      }
    },
    async logout(context) {
      try {
        await signOut(auth)
        context.commit('setUser', null)
      } catch (err) {
        throw new Error(err)
      }
    }
  }
}
