<template>
  <div class="row">
    <div class="col-md-6 col-lg-4 col-xl-3"><SkeletonItem /></div>
    <div class="col-md-6 col-lg-4 col-xl-3 d-none d-md-block">
      <SkeletonItem />
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3 d-none d-lg-block">
      <SkeletonItem />
    </div>
    <div class="col-md-6 col-lg-4 col-xl-3 d-none d-xl-block">
      <SkeletonItem />
    </div>
  </div>
</template>

<script>
import SkeletonItem from './skeleton/SkeletonItem.vue'

export default {
  components: { SkeletonItem }
}
</script>

<style>
</style>