<template>
  <PostSkeleton v-if="loading" />
  <div v-else-if="error" class="lead text-center">{{ error }}</div>
  <div v-else-if="posts.length">
    <PostList :posts="posts" @remove="handleRemove" />
  </div>
  <div v-else class="lead text-center">No posts found!</div>
  <PostModal
    title="Remove Post"
    message="Are you sure you wish to remove this post?"
    :action="handleConfimRemove"
  />
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'

import PostList from '../components/PostList'
import Spinner from '../components/Spinner'
import PostModal from '../components/PostModal'
import PostSkeleton from '../components/PostSkeleton'

import { successMsg } from '../alerts'

export default {
  name: 'home',
  components: { PostList, Spinner, PostModal, PostSkeleton },
  setup() {
    const removedPostId = ref(null)
    const loading = ref(false)
    const error = ref(null)

    const store = useStore()
    const toast = useToast()

    onMounted(async () => {
      loading.value = true

      try {
        await store.dispatch('posts/getPosts')
        loading.value = false
      } catch (err) {
        error.value = err.message
        loading.value = false
      }
    })

    const posts = computed(() => store.state.posts.posts)

    const handleRemove = (id) => {
      removedPostId.value = id
    }

    const handleConfimRemove = () => {
      store.dispatch('posts/removePost', removedPostId.value)

      document.getElementsByClassName('btn-close')[0].click()

      // alert
      toast.success(successMsg.postRemoved.replace('Firebase: ', ''), {
        id: 'postRemoved'
      })
    }

    return {
      posts,
      error,
      loading,
      handleRemove,
      handleConfimRemove
    }
  }
}
</script>
