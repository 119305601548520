// parse link header
const parseLinkHeader = (linkHeader) => {
  const linkHeadersArray = linkHeader
    .split(', ')
    .map((header) => header.split('; '))
  const linkHeadersMap = linkHeadersArray.map((header) => {
    const thisHeaderRel = header[1].replace(/"/g, '').replace('rel=', '')
    const thisHeaderUrl = header[0].slice(1, -1)
    return [thisHeaderRel, thisHeaderUrl]
  })
  return Object.fromEntries(linkHeadersMap)
}

// toggle password
function togglePassword(passwordField, eyeIcon) {
  if (passwordField.type === 'password') {
    passwordField.type = 'text'
    eyeIcon.classList.toggle('show')
  } else {
    passwordField.type = 'password'
    eyeIcon.classList.toggle('show')
  }
}

export { parseLinkHeader, togglePassword }
