import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyBcAd88lpzC9GscxRo7XdDyvGni1ee1CVI',
  authDomain: 'youdev-techblog-app.firebaseapp.com',
  projectId: 'youdev-techblog-app',
  storageBucket: 'youdev-techblog-app.appspot.com',
  messagingSenderId: '426547042539',
  appId: '1:426547042539:web:a6c88581cb9258691706ae'
}

// init firebase
initializeApp(firebaseConfig)

// init services
const auth = getAuth()
const db = getFirestore()

export { auth, db }
