<template>
  <div class="row">
    <!-- <pre>
formValues: {{ formValues }}
formErrors: {{ formErrors }}
</pre
    > -->
    <div class="col-lg-7 col-xl-5 mx-auto">
      <div class="card">
        <div class="card-header">Register</div>
        <div class="card-body">
          <form @submit.prevent="handleSubmit" novalidate>
            <div class="mb-3">
              <label for="name" class="form-label">Name</label
              ><input
                type="text"
                class="form-control form-control-lg"
                id="name"
                name="name"
                v-model="formValues.name"
              />
            </div>
            <div class="mb-3">
              <label for="email" class="form-label"
                >Email <span class="required">(required)</span></label
              ><input
                type="email"
                class="form-control form-control-lg"
                :class="{ 'is-invalid': formErrors.email }"
                id="email"
                name="email"
                v-model="formValues.email"
              />
              <div
                class="invalid-feedback fw-light d-block"
                v-if="formErrors.email"
              >
                {{ formErrors.email }}
              </div>
            </div>
            <div class="mb-3">
              <label for="password" class="form-label"
                >Password <span class="required">(required)</span></label
              >
              <div class="form-password">
                <input
                  type="password"
                  autocomplete="new-password"
                  class="form-control form-control-lg"
                  :class="{ 'is-invalid': formErrors.password }"
                  id="password"
                  name="password"
                  v-model="formValues.password"
                />
                <span class="eye" @click="handleShowHidePassword"></span>
                <div
                  class="invalid-feedback fw-light d-block"
                  v-if="formErrors.password"
                >
                  {{ formErrors.password }}
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="confirmPassword" class="form-label"
                >Confirm Password
                <span class="required">(required)</span></label
              >
              <div class="form-password">
                <input
                  type="password"
                  autocomplete="new-password"
                  class="form-control form-control-lg"
                  :class="{ 'is-invalid': formErrors.confirmPassword }"
                  id="confirmPassword"
                  name="confirmPassword"
                  v-model="formValues.confirmPassword"
                />
                <span class="eye" @click="handleShowHidePassword"></span>
                <div
                  class="invalid-feedback fw-light d-block"
                  v-if="formErrors.confirmPassword"
                >
                  {{ formErrors.confirmPassword }}
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-outline-primary btn-lg mt-2 mb-3"
            >
              Register
            </button>
          </form>
        </div>
      </div>
      <hr class="mt-4" />
      <p class="fw-light ms-1">
        Already have an account?
        <router-link to="/login" class="dotted">Login</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

import { successMsg } from '../alerts'
import { togglePassword } from '../utils'

export default {
  name: 'register',
  setup() {
    const initValues = {
      name: '',
      email: '',
      password: '',
      confirmPassword: ''
    }
    const formValues = ref({ ...initValues })
    const formErrors = ref({})
    const error = ref(null)

    const store = useStore()
    const router = useRouter()
    const toast = useToast()

    const handleShowHidePassword = (e) => {
      const passwordField =
        e.currentTarget.parentElement.getElementsByTagName('input')[0]
      const eyeIcon = e.currentTarget
      togglePassword(passwordField, eyeIcon)
    }

    const validateForm = (formValues) => {
      const errors = {}
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i

      if (!formValues.value.email.trim()) {
        errors.email = 'Email is required'
      } else if (!regex.test(formValues.value.email.trim())) {
        errors.email = 'Email is not valid'
      }

      if (!formValues.value.password) {
        errors.password = 'Password is required'
      } else if (formValues.value.password.length < 6) {
        errors.password = 'Password must be more than 6 characters'
      } else if (formValues.value.password.length > 10) {
        errors.password = 'Password cannot exceed more than 10 characters'
      }

      if (formValues.value.confirmPassword !== formValues.value.password)
        errors.confirmPassword = 'Passwords not match'

      return errors
    }

    const handleSubmit = async () => {
      formErrors.value = validateForm(formValues)
      if (Object.keys(formErrors.value).length === 0) {
        try {
          await store.dispatch('auth/register', {
            name: formValues.value.name,
            email: formValues.value.email,
            password: formValues.value.password
          })

          // clear form
          formValues.value = { ...initValues }

          // alert
          toast.success(successMsg.registered.replace('Firebase: ', ''), {
            id: 'registered'
          })

          // navigate to homepage
          router.push('/')
        } catch (err) {
          error.value = err.message

          // alert
          toast.error(error.value.replace('Firebase: ', ''), {
            id: 'registeredError'
          })
        }
      }
    }

    return {
      handleShowHidePassword,
      handleSubmit,
      formValues,
      formErrors
    }
  }
}
</script>