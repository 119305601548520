<template>
  <div class="skeleton" :class="type"></div>
</template>

<script>
export default {
  props: ['type']
}
</script>

<style scoped>
.skeleton {
  background-color: #ddd;
  border-radius: 4px;
}
.skeleton.title {
  width: 85%;
  height: 23px;
  opacity: 1;
  border-radius: 0.8rem;
}
.skeleton.tag {
  display: inline-block;
  width: 50px;
  height: 20px;
  margin-right: 2px;
  opacity: 0.5;
  border-radius: 0.8rem;
}
.skeleton.text {
  width: 100%;
  height: 16px;
  opacity: 0.4;
  border-radius: 0.6rem;
  margin-bottom: 5px;
}
.skeleton.text.text-short {
  width: 80%;
}
.skeleton.text.text-first {
  margin-top: 15px;
}
.skeleton.more {
  width: 100px;
  height: 20px;
  opacity: 0.6;
  border-radius: 0.8rem;
  margin-top: 25px;
}
</style>