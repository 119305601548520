import { createStore } from 'vuex'

// firebase
import { auth } from '../firebase/config'
import { onAuthStateChanged } from 'firebase/auth'

// modules
import authModule from './modules/auth'
import postsModule from './modules/posts'

const store = createStore({
  modules: {
    auth: authModule,
    posts: postsModule
  }
})

const unsub = onAuthStateChanged(auth, (user) => {
  store.commit('auth/setAuthIsReady', true)
  store.commit('auth/setUser', user)
  unsub()
})

export default store
