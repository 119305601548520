<template>
  <span
    v-for="tag in tags"
    :key="tag"
    class="badge bg-light fw-light mt-0 me-1"
    :class="{ 'active--': currentTags.includes(tag) }"
    ><router-link :to="{ name: 'tag', params: { tag } }" class="text-muted">{{
      tag
    }}</router-link>
  </span>
</template>

<script>
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'

export default {
  props: ['tags'],
  setup() {
    const store = useStore()

    const currentTags = computed(() => store.state.posts.currentPost.tags)

    return { currentTags }
  }
}
</script>

<style scoped>
.badge {
  background-color: rgb(226, 226, 226, 0.5) !important;
  padding: 0;
  margin: 4px 5px 3px 0;
  font-size: 12px;
  line-height: 18px;
  border-radius: 20px;
  cursor: pointer;
}
.badge a {
  display: inline-block;
  padding: 2px 10px 2px 10px;
}
.badge:hover {
  background-color: rgb(226, 226, 226, 1) !important;
}
.badge.active {
  /* background-color: #028090 !important; */ /* green theme: */
  background-color: #9b5de5 !important; /* purple theme: */
  color: #fff !important;
}
.badge.active a {
  color: #fff !important;
}
</style>