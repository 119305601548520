<template>
  <div class="spinner-wapper text-primary">
    <div class="spinner spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.spinner-wapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.spinner {
  width: 3rem;
  height: 3rem;
  border-width: 3px;
}
</style>