<template>
  <div class="shimmer-wrapper">
    <div class="shimmer"></div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.shimmer-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  animation: loading 2.5s infinite;
}
.shimmer {
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  transform: skewX(-20deg);
  box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
}
@keyframes loading {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(150%);
  }
}
</style>
