import {
  collection,
  getDocs,
  getDoc,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
  serverTimestamp,
  query,
  where
} from 'firebase/firestore'

import { db } from '@/firebase/config'

export default {
  namespaced: true,
  state: {
    posts: [],
    currentPost: null,
    tags: []
  },
  mutations: {
    setPosts(state, payload) {
      state.posts = payload
    },
    setPost(state, payload) {
      state.currentPost = payload
    },
    addPost(state, payload) {
      state.posts.push(payload)
    },
    removePost(state, payload) {
      state.posts = state.posts.filter((post) => post.id !== payload)
    },
    updatePost(state, payload) {
      state.currentPost = payload
    },
    setTags(state, payload) {
      state.tags = payload
    }
  },
  getters: {},
  actions: {
    async getPosts(context, _query) {
      let colRef = collection(db, 'posts')

      if (_query) colRef = query(colRef, where(..._query))

      try {
        // get collection data
        const snapshot = await getDocs(colRef)
        let posts = []
        snapshot.docs.forEach((doc) => {
          posts.push({ ...doc.data(), id: doc.id })
        })

        context.commit('setPosts', posts)
      } catch (err) {}
    },
    async getPost(context, id) {
      const docRef = doc(db, 'posts', id)

      try {
        const doc = await getDoc(docRef)

        context.commit('setPost', doc.data())
      } catch (err) {}
    },
    async addPost(context, { title, body, tags, uid }) {
      let colRef = collection(db, 'posts')

      try {
        const doc = await addDoc(colRef, {
          title,
          body,
          tags,
          uid,
          createdAt: serverTimestamp()
        })

        // context.commit('addPost', doc)
      } catch (err) {}
    },
    async removePost(context, id) {
      const docRef = doc(db, 'posts', id)

      try {
        await deleteDoc(docRef)

        context.commit('removePost', id)
      } catch (err) {}
    },
    async updatePost(context, { id, title, body, tags }) {
      const docRef = doc(db, 'posts', id)

      try {
        const doc = await updateDoc(docRef, {
          title,
          body,
          tags
        })

        context.commit('updatePost', doc.data())
      } catch (err) {}
    },
    async getTags(context) {
      let colRef = collection(db, 'posts')

      try {
        // get collection data
        const snapshot = await getDocs(colRef)
        let posts = []
        snapshot.docs.forEach((doc) => {
          posts.push({ ...doc.data(), id: doc.id })
        })

        const tagSet = new Set()
        posts.forEach((post) => {
          post.tags.forEach((tag) => {
            tagSet.add(tag)
          })
        })

        context.commit('setTags', [...tagSet].sort())
      } catch (err) {}
    }
  }
}
