import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { VueMasonryPlugin } from 'vue-masonry/src/masonry.plugin'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

// store
import store from './store/index'

// app instance
let app = createApp(App)

app.use(VueMasonryPlugin)
const options = {
  position: 'top-center',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
  transition: {
    enter: 'fade-enter-active',
    leave: 'Vue-Toastification__fade-leave-active',
    move: 'fade-move'
  }
}
app.use(Toast, options)
app.use(store)
app.use(router).mount('#app')
