<template>
  <div class="row">
    <!-- <pre>
formValues: {{ formValues }}
formErrors: {{ formErrors }}
tags: {{ tags }}
</pre
    > -->
    <div class="col-lg-8 col-xl-7 mx-auto">
      <div class="card">
        <div class="card-header">Add New Post</div>
        <div class="card-body">
          <form @submit.prevent="handleSubmit" novalidate>
            <div class="mb-4">
              <label for="title" class="form-label">Title</label
              ><input
                type="text"
                class="form-control form-control-lg"
                :class="{ 'is-invalid': formErrors.title }"
                id="title"
                name="title"
                v-model="formValues.title"
              />
              <div
                class="invalid-feedback fw-light d-block"
                v-if="formErrors.title"
              >
                {{ formErrors.title }}
              </div>
            </div>
            <div class="mb-4">
              <label for="tag" class="form-label">Tags</label>
              <div class="d-flex justify-content-between align-items-center">
                <input
                  type="text"
                  class="form-control form-control-lg"
                  id="tag"
                  name="tag"
                  ref="tagInput"
                  v-model="formValues.tag"
                />
                <button
                  class="btn btn-lg btn-primary ms-2 fw-light"
                  @click.prevent="handleAddTag"
                >
                  add
                </button>
              </div>
              <div v-if="tags.length" class="mt-2 fw-light">
                Current tags:
                <span
                  v-for="tag in tags"
                  :key="tag"
                  class="tag"
                  @click="handleRemove(tag)"
                  >{{ tag }} <i>&times;</i></span
                >
              </div>
            </div>
            <div class="mb-0">
              <label for="body" class="form-label">Content</label>
              <vue-simplemde
                preview-class="markdown-body"
                :class="{ 'is-invalid': formErrors.body }"
                v-model="formValues.body"
              />
              <div
                class="invalid-feedback fw-light d-block position-relative"
                v-if="formErrors.body"
                style="top: -30px"
              >
                {{ formErrors.body }}
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-outline-primary btn-lg mt-2 mb-3"
            >
              Add Post
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import VueSimplemde from 'vue-simplemde'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'

import { successMsg } from '../alerts'

export default {
  name: 'addPost',
  components: { VueSimplemde },
  setup() {
    const tagInput = ref(null)
    let tags = ref([])
    const formValues = ref({ title: '', tag: '', body: '' })
    const formErrors = ref({})

    const router = useRouter()
    const store = useStore()
    const toast = useToast()

    const user = computed(() => {
      return store.state.auth.user
    })

    const handleAddTag = () => {
      const addedTag = formValues.value.tag.trim().toLowerCase()
      if (addedTag && !tags.value.includes(addedTag)) {
        tags.value.push(addedTag.toLowerCase())
      }

      formValues.value.tag = ''
      tagInput.value.focus()
    }

    const handleRemove = (tag) => {
      tags.value = tags.value.filter((t) => t !== tag)
    }

    const validateForm = (formValues) => {
      const errors = {}

      if (!formValues.value.title.trim()) errors.title = 'Title is required'
      if (!formValues.value.body.trim()) errors.body = 'Content is required'

      return errors
    }

    const handleSubmit = () => {
      formErrors.value = validateForm(formValues)

      if (Object.keys(formErrors.value).length === 0) {
        const addedPost = {
          title: formValues.value.title.trim(),
          body: formValues.value.body.trim(),
          tags: tags.value,
          uid: user.value.uid
        }

        // CALL ACTION
        store.dispatch('posts/addPost', addedPost)

        // alert
        toast.success(successMsg.postAdded.replace('Firebase: ', ''), {
          id: 'postAdded'
        })

        router.push('/')
      }
    }

    return {
      tagInput,
      tags,
      formValues,
      handleAddTag,
      handleRemove,
      formErrors,
      handleSubmit
    }
  }
}
</script>

<style scoped>
.tag {
  display: inline-block;
  margin: 4px 5px 1px 0;
  border-radius: 20px;
  background-color: rgb(226, 226, 226, 0.5);
  padding: 2px 12px 2px 12px;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  cursor: pointer;
  font-weight: 300;
}
.tag:hover {
  background-color: rgb(226, 226, 226, 1);
}
.tag i {
  font-style: normal;
  position: relative;
  top: 2px;
  font-size: 16px;
  line-height: 16px;
}
</style>