<template>
  <div class="row">
    <div class="col-lg-7 col-xl-5 mx-auto">
      <div class="card">
        <div class="card-header">Login</div>
        <div class="card-body">
          <form @submit.prevent="handleSubmit" novalidate>
            <div class="mb-3">
              <label for="email" class="form-label">Email</label
              ><input
                type="email"
                class="form-control form-control-lg"
                id="email"
                name="email"
                v-model="formValues.email"
              />
            </div>
            <div class="mb-3">
              <label for="password" class="form-label">Password</label>
              <div class="form-password">
                <input
                  type="password"
                  autocomplete="new-password"
                  class="form-control form-control-lg"
                  id="password"
                  name="password"
                  v-model="formValues.password"
                />
                <span class="eye" @click="handleShowHidePassword"></span>
              </div>
            </div>
            <div class="mb-3">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="rememberMe"
                /><label
                  class="form-check-label position-relative"
                  for="rememberMe"
                  style="top: 1px"
                  >Remember me</label
                >
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-outline-primary btn-lg mt-2 mb-3"
            >
              Login
            </button>
          </form>
        </div>
      </div>
      <hr class="mt-4" />
      <p class="fw-light ms-1">
        Need an account?
        <router-link to="/register" class="dotted">Register</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'

import { successMsg } from '../alerts'
import { togglePassword } from '../utils'

export default {
  name: 'login',
  setup() {
    const initValues = {
      email: '',
      password: ''
    }
    const formValues = ref({ ...initValues })
    const error = ref(null)

    const store = useStore()
    const router = useRouter()
    const toast = useToast()

    const handleShowHidePassword = (e) => {
      const passwordField =
        e.currentTarget.parentElement.getElementsByTagName('input')[0]
      const eyeIcon = e.currentTarget
      togglePassword(passwordField, eyeIcon)
    }

    const handleSubmit = async () => {
      try {
        await store.dispatch('auth/login', {
          email: formValues.value.email,
          password: formValues.value.password
        })

        // clear form
        formValues.value = { ...initValues }

        // alert
        toast.success(successMsg.loggedIn.replace('Firebase: ', ''), {
          id: 'loggedIn'
        })

        // navigate to homepage
        router.push('/')
      } catch (err) {
        error.value = err.message

        // alert
        toast.error(error.value.replace('Firebase: ', ''), {
          id: 'loggedInError'
        })
      }
    }

    return { handleShowHidePassword, handleSubmit, formValues }
  }
}
</script>