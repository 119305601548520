export const successMsg = {
  registered: 'You have been successfully registered.',
  loggedOut: 'You have been successfully logged out.',
  loggedIn: 'You have been successfully logged in.',
  postAdded: 'Post has been successfully added.',
  postUpdated: 'Post has been successfully updated.',
  postRemoved: 'Post has been successfully removed.'
}

export const errorMsg = {
  registered: 'Could not complete registration.',
  loggedOut: 'Could not logout.',
  loggedIn: 'Could not login.',
  incorrectCred: 'Incorrect email address or password.',
  noFetchData: 'Could not fetch the data.',
  noPostAdded: 'Could not add post.',
  noPostUpdated: 'Could not update post.',
  noPostRemoved: 'Could not remove post.'
}
