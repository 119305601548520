<template>
  <div class="row grid">
    <div class="grid-sizer col-md-6 col-lg-4 col-xl-3 mb-4"></div>
    <div
      v-for="post in posts"
      :key="post"
      class="grid-item col-md-6 col-lg-4 col-xl-3 mb-4"
    >
      <Post :post="post" @remove="handleRemove" />
    </div>
  </div>
</template>

<script>
import Post from '../components/Post.vue'
import Masonry from 'masonry-layout'
import { onMounted, onUpdated, ref } from 'vue'

export default {
  components: { Post },
  props: ['posts'],
  setup(props, { emit }) {
    const msnryInstance = ref(null)

    const handleRemove = (id) => {
      emit('remove', id)
    }

    onMounted(() => {
      const grid = document.querySelector('.grid')
      const msnry = new Masonry(grid, {
        itemSelector: '.grid-item',
        columnWidth: '.grid-sizer',
        percentPosition: true
      })

      msnry.layout()

      msnryInstance.value = msnry
    })

    onUpdated(() => {
      msnryInstance.value.layout()
    })

    return { handleRemove }
  }
}
</script>