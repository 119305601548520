<template>
  <template v-if="!authIsReady">
    <Spinner />
  </template>
  <template v-else>
    <div class="container-vertical">
      <div class="container-vertical-content">
        <Header />
        <div class="container">
          <router-view />
        </div>
      </div>
    </div>
    <Disclaimer />
  </template>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

import Header from './components/Header.vue'
import Disclaimer from './components/Disclaimer.vue'
import Spinner from './components/Spinner.vue'

export default {
  components: {
    Header,
    Disclaimer,
    Spinner
  },
  setup() {
    const store = useStore()

    const user = computed(() => {
      return store.state.auth.user
    })

    const authIsReady = computed(() => {
      return store.state.auth.authIsReady
    })

    return { user, authIsReady }
  }
}
</script>

<style>
@import '~simplemde/dist/simplemde.min.css';
</style>
