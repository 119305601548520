import { createRouter, createWebHistory } from 'vue-router'

// firebase
import { auth } from '../firebase/config'
import { onAuthStateChanged } from 'firebase/auth'

import Home from '../views/Home'
import AddPost from '../views/AddPost'
import EditPost from '../views/EditPost'
import Login from '../views/Login'
import Register from '../views/Register'
import Tag from '../views/Tag'
import Details from '../views/Details'
import MyPosts from '../views/MyPosts'

const requireAuth = (to, from, next) => {
  const unsub = onAuthStateChanged(auth, (user) => {
    if (!user) {
      // redirect to login page
      next({ name: 'login' })
    } else {
      // go next
      next()
    }
    unsub()
  })
}

const isLoggedIn = (to, from, next) => {
  const unsub = onAuthStateChanged(auth, (user) => {
    if (user) {
      // redirect to homepage
      next({ name: 'home' })
    } else {
      // go next
      next()
    }
    unsub()
  })
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/posts/:id',
    name: 'details',
    component: Details,
    props: true
  },
  {
    path: '/add',
    name: 'addPost',
    component: AddPost,
    beforeEnter: requireAuth
  },
  {
    path: '/edit/:id',
    name: 'editPost',
    component: EditPost,
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/tags/:tag',
    name: 'tag',
    component: Tag
  },
  {
    path: '/my-posts',
    name: 'myPosts',
    component: MyPosts,
    beforeEnter: requireAuth
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: isLoggedIn
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    beforeEnter: isLoggedIn
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active'
})

export default router
